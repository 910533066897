import React from "react";
import styled from "styled-components";
import thoash from "./thoash.jpg";

const _ThePage = styled.div`
  background-image: url(${thoash});
  width: 100vw;
  height: 100vh;
  background-color: #ccc;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

function App() {
  return <_ThePage />;
}

export default App;
